import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/header/Header";
import Contact from "./pages/contact/Contact";
import Games from "./pages/games/Games";
import Home from "./pages/Home";
/* import { ReactComponent as Logo1 } from "./assets/svgs/logo-no-background.svg";
import { ReactComponent as Logo2 } from "./assets/svgs/logo-color.svg";
import { ReactComponent as Logo3 } from "./assets/svgs/logo-white.svg"; */

function App() {
  return (
    <div className="App">
      <Header className="App-header" />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route path="/games" element={<Games />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
