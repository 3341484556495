import React from "react";
import "./home.css";
import LogoImage from "../assets/png/logo-black.png";
const Home = () => {
  return (
    <div className="home-container">
      <img src={LogoImage} className="App-logo" alt="logo" />
    </div>
  );
};

export default Home;
