import React from "react";

const Contact = () => {
  return (
    <div>
      <h1>Contact</h1>
      <p>Phone: 0021650616392</p>
      <p>
        Email: <a href="mailto:so1fien@hotmail.com">so1fien@hotmail.com</a>
      </p>
    </div>
  );
};

export default Contact;
