import React from "react";
import "./recordTable.css";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import useTableRecords from "./useTableRecords";

function RecordTable({ username }) {
  const { rows, columns, getRowClassNameColor, getCellClassname } =
    useTableRecords(username);
  return (
    <div className="recordTable-container">
      username: {username}
      <img
        src={
          "https://i.etsystatic.com/26743355/r/il/86efc6/3476314445/il_680x540.3476314445_aqfz.jpg"
        }
        className="App-logo"
        alt="logo"
        style={{ width: "100vw", height: "50" }}
      />
      <Box sx={{ height: 500, width: "100vw" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.lastName}
          getRowClassName={getRowClassNameColor}
          cellClassname={getCellClassname}
          disableColumnMenu
        />
      </Box>
    </div>
  );
}

export default RecordTable;
