import React from "react";
import "./header.css";
const Header = () => {
  return (
    <div className="header-container">
      <p>Srez Consulting</p>
      <div className="header-links">
        <a href="/">Home</a>
        <a href="/games">Games</a>
        <a href="/contact">Contact</a>
      </div>
    </div>
  );
};
export default Header;
