import React from "react";
import "./games.css";
import Rami from "../../components/rami/Rami";

const Games = () => {
  return (
    <div className="games-container">
      <h1>Rami Score </h1>
      <Rami />
    </div>
  );
};

export default Games;
