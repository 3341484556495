import { Button, TextField } from "@mui/material";
import "./rami.css";
import { useState } from "react";
import RecordTable from "../recordTable/RecordTable";
const users = [
  { user: "sofien", password: "boss1" },
  { user: "ahmed", password: "boss" },
  { user: "osema", password: "9arwi" },
  { user: "hama", password: "gt2024" },
  { user: "Ali", password: "jadour" },
  { user: "ayoub", password: "kays" },
];

const Rami = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [username, setUserName] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userData = users.find(
      (user) =>
        user.user === data.get("user") && user.password === data.get("password")
    );
    if (userData) {
      setLoggedIn(true);
      setWrongCredentials(false);
      setUserName(userData.user);
    } else {
      setWrongCredentials(true);
    }
  };

  return !loggedIn ? (
    <form className="login-form" onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="user"
        label="user"
        name="user"
        autoComplete="user"
        autoFocus
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
      />
      {wrongCredentials && <p className="error-message">Wrong Credentials</p>}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className="mt-3"
      >
        Sign In
      </Button>
    </form>
  ) : (
    <RecordTable username={username} />
  );
};

export default Rami;
