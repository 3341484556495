const useTableRecords = (username) => {
  const getCellClassname = (params) => {
    if (params.row.firstName === username) {
      return "mynamerow";
    }
  };
  const columns = [
    {
      align: "left",
      headerAlign: "left",
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 150,
      valueGetter: (value, row) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      cellClassName: getCellClassname,
    },
    {
      field: "played",

      align: "left",
      headerAlign: "left",
      headerName: "P",
      type: "number",
      width: 60,
    },
    {
      field: "win",
      align: "left",
      headerAlign: "left",
      headerName: "W",
      type: "number",
      width: 60,
    },
    {
      field: "lose",
      align: "left",
      headerAlign: "left",
      headerName: "L",
      type: "number",
      width: 60,
      valueGetter: (value, row) => row.played - row.win,
    },
    {
      field: "percentage",
      align: "left",
      headerAlign: "left",
      headerName: "%",
      type: "number",
      width: 70,

      valueGetter: (value, row) => {
        const percentage = row.win / row.played;
        return percentage ? Math.round(percentage * 10000) / 100 : 0;
      },
    },
  ];

  const rows = [
    { lastName: "srez", firstName: "sofien", played: 4, win: 2 },
    { lastName: "boss", firstName: "ahmed", played: 3, win: 2 },
    { lastName: "gtaifi", firstName: "hama", played: 3, win: 1 },
    { lastName: "9arwi", firstName: "osema", played: 3, win: 1 },
    { lastName: "sousou", firstName: "saif", played: 1, win: 1 },
    { lastName: "chef", firstName: "ali", played: 1, win: 0 },
    { lastName: "kays", firstName: "ayoub", played: 1, win: 1 },
  ];
  const getRowClassNameColor = (params) => {
    if (params.row.win / params.row.played > 0.5) {
      return "win";
    } else if (params.row.win / params.row.played < 0.5) {
      return "lose";
    } else {
      return "draw";
    }
  };

  return { rows, columns, getRowClassNameColor };
};
export default useTableRecords;
